@font-face {
  font-family: "DIN";
  src: local("DIN-light"),
   url("./fonts/DINOffcPro-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "DIN";
  src: local("DIN"),
   url("./fonts/DINOffcPro.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "DIN";
  src: local("DIN-bold"),
   url("./fonts/DINOffcPro-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "DIN";
  src: local("DIN-black"),
   url("./fonts/DINOffcPro-Black.ttf") format("truetype");
  font-weight: 800;
}

body {
  margin: 0;
  font-family: "DIN", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, p, span, a, ul, li {
  font-family: "DIN", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 130%;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;  
}




