/* 
===============================================================================
SECTIONS
===============================================================================
*/

/* HEADER */

.header-section {
  box-shadow: 0 0px 15px 0px grey;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 100;
}

.header-section .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10vw;
}

.header-section .logo-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1vw 0;
  z-index: 52;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-section .logo {
  width: 6.6vw;
  display: block;
}

.nav-wrapper ul,
.nav-wrapper-mb ul {
  list-style: none;
}

.nav-wrapper ul {
  padding: 0 1.5vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav-wrapper {
  position: static;
  background-color: transparent;
  display: flex;
}

.header-section .nav-wrapper ul li a,
.header-section .nav-wrapper-mb ul li a {
  text-transform: uppercase;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
}

.header-section .nav-wrapper ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  font-size: 0.75vw;
  padding: 0 1.8vw;
  display: flex;
  color: #000000;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 4vw;
}

/* NAV ICON MOBILE/TAB */

.nav-icon-mb {
  width: 28px;
  height: 24px;
  position: relative;
  margin: 0 0 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  z-index: 100;
}

.nav-icon-mb span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #000000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.nav-icon-mb span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon-mb span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon-mb span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon-mb.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
  left: 0;
}

.nav-icon-mb.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.nav-icon-mb.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 23px;
  left: 0;
}

.nav-wrapper-mb.open {
  top: 0;
  overflow: hidden;
}

.nav-wrapper-mb {
  position: fixed;
  left: 0;
  top: -200%;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 50;
}

/* BANNER SECTION */

.banner-wrapper img {
  display: block;
  height: auto;
  width: 100%;
}

/* ABOUT SECTION */

.about-section .container {
  display: flex;
  padding: 0;
}

.about-section .container {
  position: absolute;
  top: 33.5vw;
}

.about-section p {
  padding-bottom: 0;
}

.about-section .container > div {
  flex: 1;
}

.about-section .container .div1 {
  padding: 3.05vw 0;
}

.about-section .div1 h2,
.about-section .div1 p {
  padding-left: 12.6vw;
  padding-right: 2vw;
}

.about-section .div2 h3,
.about-section .div2 p,
.about-section .div2 .cta-wrapper {
  padding-left: 10vw;
  padding-right: 12.3vw;
}

.about-section .container .div2 {
  padding: 0 2px 0 3vw;
}

.about-section .div2 .cta-wrapper {
  padding-top: 20px;
  padding-bottom: 10px;
}

.green-oval {
  background: linear-gradient(0.25turn, #c2d500, #c8d602, #dcd700, #ecd900);
  border-radius: 20vw;
  padding: 3.1vw 0 2vw;
}

/* DATA POINTS SECTION */

.data-point-section {
  padding-top: 2.5vw;
  padding-bottom: 1.5vw;
}

.data-point-section .data-point-content-wrapper {
  display: flex;
  justify-content: center;
  padding: 1vw 6vw;
}

.data-point-section .data-point-content-wrapper > div {
  flex: 1;
}

.data-point-section .header-wrapper .main-bodycopy {
  margin: 0 20vw;
}

.data-point-section .label-wrapper {
  padding: 1vw 0 3vw;
}

.data-point-section .label-wrapper p {
  margin: 0 8vw;
}

.data-point-section .donut-wrapper {
  border-left: 1px solid #000000;
}

.data-point-section
  .data-point-content-wrapper
  > div:first-child
  .donut-wrapper {
  border-left: none;
}

.data-point-section .donut-wrapper .percentage sup {
  font-size: 1.8vw;
  vertical-align: super;
}

.data-point-section .donut-wrapper .percentage {
  font-size: 5.8vw;
  font-weight: 600;
  line-height: 110%;
  margin: 1vw 0 0 1vw;
  text-transform: uppercase;
}

.data-point-section .donut-wrapper .desc {
  font-size: 0.88vw;
  font-weight: 600;
  line-height: 110%;
  margin: 0 1.6vw;
  text-transform: uppercase;
}

.data-point-section .last-p {
  padding: 4vw 0 0;
}

/* BENEFIT SECTION */

.benefits-section {
  padding-top: 7vw;
  padding-bottom: 5vw;
}

.benefits-section .header-wrapper .main-bodycopy {
  margin: 0 27vw 0 0;
}

.benefits-section .benefit-content-wrapper {
  display: flex;
  padding: 3.5vw 0 2vw;
}

.benefits-section .benefit-content-wrapper > div {
  flex: 1;
}

.benefits-section .benefit-content-wrapper .benefit-item-1 .list-wrapper ul {
  padding: 0 5.5vw 0 1.2vw;
}

.benefits-section .benefit-content-wrapper .benefit-item-2 .list-wrapper ul {
  padding: 0 3.5vw 0 1.2vw;
}

.benefits-section .benefit-content-wrapper .list-wrapper {
  display: flex;
}

.benefits-section .benefit-content-wrapper .list-wrapper > div {
  flex: 1;
}

.benefit-content-wrapper .ico-info-wrapper img {
  width: 5vw;
  height: auto;
  padding: 0 0 1vw 0;
}

.benefit-content-wrapper .h5 {
  padding: 10px 0 5px;
}

.benefits-section .last-p {
  color: #dc3545;
  font-size: 0.78vw;
  font-weight: 600;
  line-height: 100%;
  padding: 10px 0;
  text-transform: none;
}

/* QUALIFIERS SECTION */

.qualifiers-section {
  background: #f2f2f2;
  padding-top: 4vw;
  padding-bottom: 5vw;
}

.qualifiers-section .qualifier-content-wrapper {
  display: flex;
  padding: 5vw 12.6vw 0 0;
  flex-wrap: wrap;
}

.qualifiers-section .qualifier-content-wrapper > div {
  flex: 1;
}

.qualifiers-section .title-wrapper div {
  border-radius: 0 80vw 80vw 0;
  display: flex;
  cursor: pointer;
  padding: 1.25vw 0;
  transition: 0.4s ease-in-out;
}

.qualifiers-section .title-wrapper .h5 {
  padding: 0;
  margin: 0 0 0 12.6vw;
  font-weight: 500;
  text-transform: uppercase;
}

.qualifiers-section .title-wrapper img {
  display: none;
  height: 1.3vw;
  align-self: center;
  margin: auto 1.8vw auto auto;
}

.qualifiers-section .title-wrapper .h5:hover {
  font-weight: 600;
  transition: 0.4s ease-in-out;
}

.qualifiers-section .title-wrapper .selected {
  background: linear-gradient(0.25turn, #f2f2f2, #ffffff, #ffffff);
}

.qualifiers-section .title-wrapper .selected .h5 {
  font-weight: 600;
}

.qualifiers-section .title-wrapper .selected img {
  display: block;
}

.qualifiers-section .description-wrapper {
  display: flex;
  padding: 0 0 0 10vw;
}

.qualifiers-section .description-wrapper .first-half {
  padding-right: 2.5vw;
}

.qualifiers-section .description-wrapper li,
.qualifiers-section .description-wrapper .main-bodycopy {
  margin: 1.1vw 0;
  transition: 0.4s ease-in-out;
}

.qualifiers-section .in-addition {
  padding: 2vw 12.6vw 0;
}

.qualifiers-section .last-p {
  color: #dc3545;
  font-size: 0.78vw;
  font-weight: 600;
  line-height: 100%;
  padding: 10px 0;
  text-transform: none;
}

/* PROCESS SECTION */

.process-section {
  padding-top: 4vw;
  padding-bottom: 10vw;
}

.process-section .header-wrapper {
  padding-bottom: 5vw;
}

.process-section h4,
.process-section p {
  color: #ffffff;
}

.process-section h4 {
  font-size: 1.6vw;
  font-weight: 500;
  padding-bottom: 1vw;
  padding-top: 0.5vw;
  text-transform: uppercase;
}

.process-section .main-bodycopy {
  padding-top: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.process-section .process-content-wrapper {
  height: 14vw;
}

.process-section .process-content-wrapper > div {
  height: 14vw;
  position: relative;
  padding: 2vw 2.5vw 1.5vw 14.2vw;
  width: 32vw;
  top: 0;
}

.process-section .line {
  background: #ffffff;
  display: block;
  height: 1px;
  position: relative;
  top: 2.28vw;
  left: 13vw;
  width: 62vw;
  z-index: 100;
}

.process-section .process-content-wrapper .dot {
  background: #ffffff;
  margin: 0 0 0 0.4vw;
}

.process-section .process-content-wrapper > div:nth-child(1) {
  background: linear-gradient(0.25turn, #3d8eb9, #549c97, #8fba47, #bed405);
  padding-left: 12.6vw;
  left: 0;
  z-index: 99;
}

.process-section .process-content-wrapper > div:nth-child(2) {
  background: linear-gradient(0.25turn, #f63c13, #fb5607, #ff6900);
  left: 20vw;
  top: -14vw;
  z-index: 98;
}

.process-section .process-content-wrapper > div:nth-child(3) {
  background: linear-gradient(0.25turn, #397498, #1486b2, #0090bf);
  left: 40vw;
  top: -28vw;
  z-index: 97;
}

.process-section .process-content-wrapper > div:nth-child(4) {
  background: linear-gradient(0.25turn, #3e3e3e, #1d1d1d, #010101);
  left: 60vw;
  top: -42vw;
  z-index: 96;
}

/* JUDGES SECTION */

.judges-section {
  background: #f2f2f2;
  padding-top: 3.5vw;
  padding-bottom: 8vw;
}

.judges-section .judges-content-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 2vw 0 3.5vw;
}

.judges-section .judges-content-wrapper img {
  margin-bottom: 1vw;
  width: 12vw;
  height: 12vw;
  background: linear-gradient(#e66465, #9198e5);
  border-radius: 100%;
}

.judges-section .ico-info-wrapper.t-center {
  width: 15vw;
  height: 19vw;
}

.judges-section .judges-content-wrapper .ico-desc {
  font-size: 1vw;
}

.judges-section .judges-content-wrapper h4,
.judges-section .judges-content-wrapper p {
  text-align: center;
}

.judges-section .clicker {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}

.judges-section .clicker div,
.judges-section .clicker img {
  cursor: pointer;
  margin: 0 1vw;
}

.judges-section .clicker img {
  cursor: pointer;
  height: 1.6vw;
  transition: 0.4s ease-in-out;
}

.judges-section .clicker img:hover {
  transition: 0.4s ease-in-out;
  transform: scale(1.3);
}

.judges-section .dot {
  background: #ffffff;
  border: 1.5px solid #ffffff;
  transition: 0.4s ease-in-out;
}

.judges-section .current.dot {
  transition: 0.4s ease-in-out;
  background: #ff6b00;
}


/* HOW TO APPLY SECTION */

.howtoapply-section {
  height: 19.5vw;
  padding: 3vw 0;
}

.howtoapply-section .container {
  position: relative;
}

.howtoapply-section .yellow-oval {
  background: linear-gradient(0.25turn, #ffda00, #fedb00, #ffd400, #ffb901);
  border-radius: 20vw;
  padding: 2.5vw 21vw;
  position: absolute;
  top: -6.5vw;
  right: 12.6vw;
  left: 12.6vw;
}

.howtoapply-section h2,
.howtoapply-section p,
.howtoapply-section .cta-wrapper {
  text-align: center;
}

.howtoapply-section .apply-list a {
  color: inherit;
  text-decoration: underline;
}

.howtoapply-section .apply-list {
  margin: 0 auto;
  text-align: left;
  display: table;
}

.howtoapply-section .cta-wrapper {
  padding: 1.2vw 0 0.3vw;
}

/* Articles SECTION */

.articles-section {
  background: #f2f2f2;
  padding-top: 8.5vw;
  padding-bottom: 8vw;
}

.articles-section .articles-content-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.articles-section .articles-content-wrapper .article{
  width: 36vw;
  height: 6.5vw;
  color: #008FBE;
  background-color: #ffff;
  display: inline-block;
  border-radius: 1vw;
  padding: .8vw 2vw;
}

.articles-section .articles-content-wrapper .article-type{
  display: flex;
  align-items: center;
  margin-bottom: .25vw;;
}

.articles-section .articles-content-wrapper .icon{
  width: 1vw;
  text-transform: uppercase;
  margin-right: .5vw;
}

.articles-section .articles-content-wrapper .type{
  font-size: .8vw;
  text-transform: uppercase;
}
.articles-section .articles-content-wrapper .text{
  font-size: 1.4vw;
  font-weight: 700;
}


/* CONNECT SECTION */

.connect-section {
  padding-top: 3.5vw;
  padding-bottom: 3.5vw;
}

.connect-section .container {
  position: relative;
  z-index: 90;
}

.connect-section .header-wrapper {
  padding-bottom: 2.2vw;
}

.connect-section .header-wrapper .h2 {
  padding: 0;
}

.connect-section .connect-content-wrapper {
  display: flex;
}

.connect-section .connect-content-wrapper .copy-wrapper {
  padding-bottom: 1.5vw;
}

.connect-section .connect-content-wrapper .copy-wrapper p {
  padding: 0;
}

.connect-section .connect-content-wrapper .dbl-cta-wrapper {
  padding: 1.5vw 0;
}

.connect-section .connect-content-wrapper .dbl-cta-wrapper > div {
  padding-right: 1vw;
}

.connect-content-wrapper .right-section {
  padding-left: 9.5vw;
  padding-top: 1.7vw; /* Padding top added for video */
}

.connect-content-wrapper .right-section img,
.connect-content-wrapper .right-section .player {
  width: 32.5vw;
}

/* CONTEST RULES SECTION */

.contestrules-section {
  padding-top: 1vw;
}

.contestrules-section .container {
  background-color: #F2F2F2;
  padding-top: 2vw;
  padding-bottom: 4vw;
}

.contestrules-section ol {
  margin: 1vw 1vw 1vw 1.4vw;
  padding: 0;
}

.contestrules-section ul {
  margin: 1vw;
  padding: 0;
}

.contestrules-section .h5 {
  padding: 2vw 0;
  line-height: 150%;
}

.contestrules-section p {
  padding: 0;
}

.contestrules-section .main-bodycopy {
  line-height: 150%;
}

.contestrules-section ul li::marker {
  font-size: 0.8vw;
}

/*
.contestrules-section span.highlight {
  color: #dc3545;
  font-weight: 600;
}
*/
  
/* FOOTER SECTION */

.footer-section {
  padding-top: 1vw;
  padding-bottom: 4vw;
}

.footer-section .container {
  display: flex;
  height: 4vw;
  justify-content: center;
}

.footer-section .nav-wrapper ul {
  padding: 0;
}

.footer-section .nav-wrapper li {
  margin: auto;
  padding: 0;
}

.footer-section .nav-wrapper li {
  border-left: 1px solid #000000;
}

.footer-section .nav-wrapper li:nth-child(1) {
  border-left: none;
  padding-left: 0;
}

.footer-section .nav-wrapper a {
  font-size: 0.7vw;
  color: #000000;
}

.footer-section .nav-wrapper li {
  padding: 0 0.8vw;
  font-size: 0.7vw;
  color: #000000;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.footer-section .footer-icons {
  align-items: center;
  display: flex;
}

.footer-section .footer-icons a {
  flex: 1;
  padding: 0 0.8vw;
}

.footer-section .footer-icons img {
  height: 1.1vw;
}

.footer-section .nav-wrapper a:hover {
  text-decoration: underline;
}

/* STICKY BANNER SECTION */

.sticky-banner-section {
  background-color: #000000;
  box-shadow: 0 0px 15px 0px grey;
  bottom: -1px;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.sticky-banner-section .sticky-banner-wrapper {
  display: flex;
  justify-content: center;
  padding: 0.5vw 1vw;
}

.sticky-banner-section p {
  color: #ffffff;
}

.sticky-banner-section .sticky-banner-wrapper .main-bodycopy {
  text-align: right;
}

.sticky-banner-section .sticky-banner-wrapper p {
  margin: auto 30px auto 0;
}

.sticky-banner-section .contest-rules-cta {
  color: #ffffff;
  text-decoration: underline;
}

.sticky-banner-section .contest-rules-cta:hover {
  color: #dc3545;
  text-shadow: 4px 4px 20px white;
}

/* OVERLAY SECTION */

.download-overlay-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 150;
}

.download-overlay-wrapper .outer {
  box-shadow: 0 0 30px 10px #5A5A5A;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  background: #F2F2F2;
  width: 45vw;
  height: 24vw;
  z-index: 160;
}

.download-overlay-wrapper .container {
  padding: 2vw 12.2vw;
  width: 100%;
}

.download-overlay-wrapper .checkboxes > div {
  padding: 0.5vw 0;
}

.download-overlay-wrapper .checkboxes div > input,
.download-overlay-wrapper .checkboxes div > label {
  display: inline-block;
}

.download-overlay-wrapper .checkboxes div > label {
  width: 90%;
}

.download-overlay-wrapper label {
  color: #000000;
  font-size: 1vw;
  font-weight: 600;
  line-height: 150%;
}

.download-overlay-wrapper label .blue-highlight {
  color: #008FBE;
  text-decoration: underline;
  font-weight: 600;
}

.download-overlay-wrapper .dbl-cta-wrapper {
  justify-content: space-around;
  padding: 2vw 0 0 0;
}

.download-overlay-wrapper .download-button a {
  padding: 0.2vw 0;
}

.download-overlay-wrapper .back-button div {
  padding: 0.66vw 0;
}

.download-overlay-wrapper .close-button {
  width: 1.5vw;
  height: 1.5vw;
  position: relative;
  margin: 1vw 1vw 1vw auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.download-overlay-wrapper .close-button span {
  display: block;
  position: absolute;
  height: 0.2vw;
  width: 100%;
  background: #000000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.download-overlay-wrapper .close-button span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0.6vw;
  left: 0;
}

.download-overlay-wrapper .close-button span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0.6vw;
  left: 0;
}

.download-overlay-wrapper .close-button:hover span {
  background-color: #dc3545;
}

.download-overlay-wrapper .radio {
  box-shadow: 1px 1px 8px gray;
  background-color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: 0.8vw;
  height: 0.8vw;
  border-radius: 50%;
  outline: none;
  border: 1px solid #FFFFFF;
  vertical-align: top;
  margin: 0.38vw 0.8vw 0 0;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
}

.download-overlay-wrapper .radio .inner-radio-active {
  display: block;
  width: 0.6vw;
  height: 0.6vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #ff6b00;
  border-color:#ffffff;
}

/* 
===============================================================================
UIs/OTHER STYLES
===============================================================================
*/

/* DOT */

.dot {
  border-radius: 100%;
  height: 0.5vw;
  width: 0.5vw;
}

/* SEMI CIRCLE BG */

.semi-circle-l {
  border-radius: 130vw 0 0 130vw;
}

.semi-circle-r {
  border-radius: 0 130vw 130vw 0;
}

/* CAROUSEL */

.carousel__list {
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 75vw;
  margin: 0 auto;
  max-width: 50rem;
}

.carousel__item {
  flex: 0 0 auto;
  width: 100%;
  padding: 0;
  margin: 0;
}

/* CTA STYLES */

.cta-wrapper a,
.cta-wrapper div {
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.7vw;
  margin: auto;
  padding: 0.6vw 0;
  text-align: center;
  text-transform: uppercase;
  width: 9vw;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.cta-wrapper a:hover,
.cta-wrapper div:hover {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.cta-wrapper .orange {
  background-color: transparent;
  border: 1px solid #ff6b00;
  color: #000000;
}

.cta-wrapper .orange-b {
  background-color: #ff6b00;
  border: 1px solid #ff6b00;
  color: #ffffff;
}

.cta-wrapper .red {
  background-color: transparent;
  border: 1px solid #dc3545;
  color: #000000;
  pointer-events: none;
  opacity: 0.8;
}

.cta-wrapper .red-b {
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: #ffffff;
}

.cta-wrapper .black {
  background-color: #000000;
  border: 1px solid #000000;
  color: #ffffff;
}

.cta-wrapper .inactive {
  background-color: #b8b8b8 !important;
  border: 1px solid #b8b8b8 !important;
  color: #FFFFFF !important;
  pointer-events: none !important;
  opacity: 0.8 !important;
}

/* CTA HOVER/FOCUS STYLES */

.orange:hover,
.orange.focus {
  background-color: #ff6b00 !important;
  color: #ffffff !important;
}

.orange-b:hover,
.orange-b.focus {
  background-color: #ffffff;
  color: #000000;
}

.red:hover,
.red.focus {
  background-color: #dc3545 !important;
  color: #ffffff !important;
}

.red-b:hover,
.red-b.focus {
  background-color: #ffffff;
  color: #000000;
}

.black:hover,
.black.focus {
  background-color: #ffffff;
  color: #000000;
}

.yellow:hover,
.yellow.focus {
  background-color: #ffd301 !important;
}

.green:hover,
.green.focus {
  background-color: #c1d402 !important;
}

.blue:hover,
.blue.focus {
  background-color: #008fbe !important;
  color: #ffffff !important;
}


/* SHARE SOCIAL */

.share-wrapper {
  padding: 1.5em 0 calc(5px + 3em) 0;
}

.share-wrapper ul{
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  vertical-align: middle;
}
.share-wrapper ul li{
  padding: 0 .5em;
  height: 2em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.share-wrapper ul li:first-child{
  border-right:  calc(1px + .03em) solid #000;
  padding: 0 .8em .01em 0;
}
.share-wrapper ul li:nth-child(2){
  padding-left: .9em;
}

.share-wrapper span{
  font-size: .75em;
  font-weight: 800;
  text-transform: uppercase;
}



.share-wrapper .js-btn-social img{
  height: 1.1em;
  width: auto;
}

.share-wrapper .js-btn-social.twitter{
  height: 1.05em;
}



/* FLOATING IMG */

.floating-img-wrapper {
  position: relative;
  z-index: 1;
}

.floating-img-wrapper img {
  position: absolute;
}

/* GRAPHIC BEAMS */

.graphic-beams-wrapper {
  top: -20vw;
}

.graphic-beams-wrapper img {
  right: 0;
  width: 25vw;
}

/* GRAPHIC BEAMS 2 */

.graphic-beams-2-wrapper {
  top: -27.8vw;
}

.graphic-beams-2-wrapper img {
  right: 0;
  width: 36vw;
}

/* HEADER ICON */

.header-icon-wrapper img {
  left: 4.2vw;
  width: 4vw;
}

/* HEADLINES */

.h1 {
  font-size: 2vw;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
}

.h2 {
  font-size: 1.6vw;
  font-weight: 600;
  padding: 15px 0;
  margin: 0;
  text-transform: uppercase;
}

.h4 {
  font-size: 1.4vw;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
  text-transform: none;
}

.h5 {
  font-size: 1.15vw;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
  text-transform: none;
}

.subhead {
  font-size: 1.85vw;
  font-weight: 600;
  text-transform: none;
  padding: 10px 0;
}

.r-margin {
  margin: 0 80% 0 0;
}

.l-margin {
  margin: 0 0 0 80%;
}

/* BODYCOPY */

.main-bodycopy {
  font-size: 1.12vw;
  font-weight: 500;
  line-height: 150%;
  padding: 10px 0;
  text-transform: none;
}

/* DONUT */

.donut-chart {
  display: block;
  border-radius: 100%;
  width: 14vw;
  height: 14vw;
  margin: auto;
  position: relative;
}

.donut-1 .donut-chart {
  background: conic-gradient(#ff6b00 0deg 288deg, #f2f2f2 288deg 360deg);
}

.donut-2 .donut-chart {
  background: conic-gradient(#ff6b00 0deg 18deg, #f2f2f2 18deg 360deg);
}

.inner-circle {
  display: block;
  position: absolute;
  width: 12.5vw;
  height: 12.5vw;
  background: #ffffff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

/* ACCORDION */

.accordion-wrapper {
  padding: 10vw 12vw 4vw 5.8vw;
}

.accordion-wrapper .acc-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-wrapper .acc-title img {
  transform: rotateZ(90deg);
  width: 2vw;
  height: 4vw;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}

.accordion-wrapper .accordion {
  background: #ffffff;
  margin-bottom: 0.8vw;
}

.accordion-wrapper .acc-title {
  cursor: pointer;
}

.accordion-wrapper .acc-title .h5 {
  text-transform: uppercase;
  padding: 2vw 0;
}

.accordion-wrapper .acc-title img.selected {
  transform: rotateZ(270deg);
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}

.accordion-wrapper .acc-title,
.accordion-wrapper .acc-description {
  padding: 0 3vw;
}

.accordion-wrapper .acc-description {
  padding-top: 1.5vw;
  padding-bottom: 4vw;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.accordion-wrapper .acc-description ul {
  padding: 0 0 0 2.4vw;
}

.accordion-wrapper .acc-description li {
    padding: 10px 0 10px 15px;
    margin: 0;
}

/* 
===============================================================================
GLOBAL
===============================================================================
*/

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 800;
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.hide-desktop {
  display: none;
}

.show-desktop {
  display: block;
}

.content-wrapper {
  background-color: #ffffff;
  bottom: 80px;
  top: 3vw;
  position: relative;
}

.content-wrapper li::marker {
  color: #ff6b00;
}

.content-wrapper li {
  padding: 0 0 0 0.6vw;
  margin: 10px 0;
}

.container {
  padding: 0 12.6vw;
}

.dbl-cta-wrapper {
  display: flex;
}

.of-hidden {
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .header-section .container {
    padding: 0 8vw;
  }
  .container {
    padding: 0 11.6vw;
  }
}

/* 
===============================================================================
 TABLET 
===============================================================================
*/

@media screen and (max-width: 1024px) {
  .hide-desktop {
    display: block;
  }

  .show-desktop {
    display: none !important;
  }

  .container {
    padding: 0 5.8vw;
  }

  .content-wrapper {
    top: 6.5vw;
  }

  .content-wrapper li {
    margin: 15px 0;
  }

  /* HEADER SECTION */

  .header-section .container {
    padding: 0 2.5vw;
  }

  .header-section .logo-wrapper {
    padding: 2vw 0;
  }

  .header-section .logo {
    min-width: 110px;
    max-width: 150px;
    width: 12vw;
    display: block;
  }

  /* NAV ICON MOBILE/TAB */

  .header-section .nav-wrapper-mb li a {
    color: #000000;
    font-size: 18px;
    padding: 2vw 3.5vw;
    display: block;
  }

  .header-section .nav-wrapper-mb li {
    text-align: right;
  }

  .header-section .nav-wrapper-mb.open ul {
    padding-top: 80px;
    padding-bottom: 1.5vw;
  }

  /* ABOUT SECTION */

  .about-section .container {
    display: block;
    top: -18vw;
    padding: 0;
    position: relative;
  }

  .about-section .container .div1 {
    padding: 4vw 5.8vw 8vw;
  }

  .about-section .div1 h2,
  .about-section .div1 p {
    padding-left: 0;
    padding-right: 0;
  }

  .about-section .div1 h2 {
    margin-right: 10vw;
  }

  .about-section .container .div2 {
    padding: 0 3vw;
  }

  .about-section .div2 h3,
  .about-section .div2 p,
  .about-section .div2 .cta-wrapper {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }

  .about-section .div2 .cta-wrapper {
    padding-top: 5vw;
    padding-bottom: 0;
  }

  .green-oval {
    border-radius: 30vw;
    padding: 9vw 10vw 8.8vw;
  }

  /* DATA POINTS SECTION */

  .data-point-section {
    padding-top: 0;
    padding-bottom: 1.5vw;
  }

  .data-point-section .header-wrapper .main-bodycopy,
  .data-point-section .last-p {
    margin: 0px 12vw;
  }

  .data-point-section .header-wrapper .h2 {
    padding-top: 0;
  }

  .data-point-section .data-point-content-wrapper {
    display: block;
    padding: 1vw 6vw;
  }

  .data-point-section .data-point-content-wrapper > div {
    padding: 2vw 0;
  }

  .data-point-section .label-wrapper p {
    margin: 0px 18vw;
  }

  .data-point-section .donut-wrapper {
    border-left: none;
  }

  .data-point-section .donut-wrapper .percentage {
    font-size: 15vw;
    margin: 3vw 0 0 3vw;
  }

  .data-point-section .donut-wrapper .desc {
    font-size: 2.2vw;
    margin: 0 6vw;
  }

  .data-point-section .donut-wrapper .percentage sup {
    font-size: 35%;
  }

  /* DONUT */

  .donut-chart {
    display: block;
    border-radius: 100%;
    width: 35vw;
    height: 35vw;
    margin: auto;
    position: relative;
  }

  .inner-circle {
    display: block;
    position: absolute;
    width: 31.5vw;
    height: 31.5vw;
    background: #ffffff;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  /* BENEFITS SECTION */

  .benefits-section {
    padding-top: 14vw;
    padding-bottom: 10vw;
  }

  .benefits-section .header-wrapper .h2 {
    margin: 0 0 0 14vw;
    padding: 0;
  }

  .benefits-section .header-wrapper .main-bodycopy {
    margin: 4vw 0;
  }

  .benefits-section .benefit-content-wrapper {
      display: block;
      padding: 0;
  }

  .benefits-section .benefit-content-wrapper > div {
    padding: 3vw 0;
}

  .benefit-content-wrapper .ico-info-wrapper img {
    width: 14vw;
  }

  .benefits-section .benefit-content-wrapper .list-wrapper ul {
    padding: 0 10vw 0 2.5vw !important;
  }

  .benefits-section .last-p {
    font-size: 2vw;
    line-height: 150%;
    padding: 1vw 20vw 1vw 0;
  }

  /* QUALIFIERS SECTION */

  .qualifiers-section {
    padding-top: 10vw;
    padding-bottom: 10vw;
  }

  .qualifiers-section .header-wrapper .h2 {
    margin: 0 0 0 14vw;
  }

  .qualifiers-section .in-addition {
    padding: 0 5.8vw 14vw;
  }
  
  .qualifiers-section .last-p {
    font-size: 2vw;
    line-height: 150%;
    text-transform: none;
  }

  /* PROCESS SECTION */

  .process-section {
    padding-top: 10vw;
    padding-bottom: 10vw;
  }

  .process-section .header-wrapper {
      padding-bottom: 10vw;
  }
  
  .process-section .header-wrapper .h2 {
    margin: 0 0 0 14vw;
  }

  .process-section .process-content-wrapper {
      height: 112vw;
      padding: 0 5.8vw;
  }

  .process-section h4 {
    font-size: 3.8vw;
  }

  .dot {
    height: 1.2vw;
    width: 1.2vw;
  }

  .process-section .dot {
    position: absolute;
    left: 6vw;
    top: 14.5vw;
  }

  .process-1 .dot {
    top: 11vw;
  }

  .process-section .line {
      position: relative;
      top: 53vw;
      left: -29vw;
      width: 83.5vw;
      z-index: 100;
      transform: rotateZ(270deg);
  }

  .process-section .process-content-wrapper > div {
    height: 32vw;
    padding: 11.6vw 11vw;
    position: relative;
    left: 0 !important;
    width: 100%;
    border-radius: 1vw 1vw 4vw 4vw;
  }

  .process-section .process-content-wrapper > div:nth-child(1) {
    background: linear-gradient(#4f9a9f, #90bb45);
    height: 28vw;
    padding: 8vw 11vw;
  }

  .process-section .process-content-wrapper > div:nth-child(2) {
    background: linear-gradient(#ee1223, #fe6305);
    top: -4vw;
  }

  .process-section .process-content-wrapper > div:nth-child(3) {
    background: linear-gradient(#96495c, #4b6c8c);
    top: -8vw;
  }

  .process-section .process-content-wrapper > div:nth-child(4) {
    background: linear-gradient(0.35turn, #3e3e3e, #1d1d1d, #010101);
    top: -12vw;
  }

  /* JUDGES SECTION */

  .judges-section {
    padding-top: 10vw;
    padding-bottom: 18vw;
  }

  .judges-section .header-wrapper {
      padding-bottom: 3.5vw;
  }
  
  .judges-section .header-wrapper .h2 {
    margin: 0 0 0 14vw;
  }

  .judges-section .ico-info-wrapper.t-center {
    width: 60vw;
    height: 48vw;
  }

  .judges-section .judges-content-wrapper img {
    margin-bottom: 3vw;
    width: 30vw;
    height: 30vw;
  }

  .judges-section .judges-content-wrapper .ico-desc {
    font-size: 3vw;
  }

  .judges-section .clicker {
    position: relative;
  }

  .judges-section .clicker img {
    height: 3.5vw;
    position: absolute;
    top: -38vw;
  }

  .judges-section .clicker .left-arrow {
    left: 5.8vw;
  }

  .judges-section .clicker .right-arrow {
    right: 5.8vw;
  }

  .judges-section .clicker .dot {
    margin: 0 2vw;
    border: 1.5px solid #ffffff;
  }

  /* HOW TO APPLY SECTION */

  .howtoapply-section {
    height: 52vw;
    padding: 3vw 0;
  }

  .howtoapply-section .yellow-oval {
    border-radius: 30vw;
    padding: 4vw 18vw 8vw;
    top: -14.5vw;
    right: 5.8vw;
    left: 5.8vw;
  }

  .howtoapply-section .cta-wrapper {
    padding: 3vw 0 0;
  }
  
  /* CONNECT SECTION */

  .connect-section {
    padding-top: 10vw;
    padding-bottom: 14.6vw;
  }

  .connect-section .header-wrapper {
      padding-bottom: 3.5vw;
  }
  
  .connect-section .header-wrapper .h2 {
    margin: 0 0 0 14vw;
  }

  .connect-section .connect-content-wrapper {
    display: block;
  }
  
  .connect-section .left-section {
    padding: 1.5vw 3.5vw 0 14.4vw;
  }

  .connect-section .right-section {
    text-align: center;
    padding: 3vw 0 0 0;
  }

  .connect-content-wrapper .right-section img {
    position: relative;
    width: 94%;
    left: 3%;
  }
   .connect-content-wrapper .right-section .player {
    width: 94% !important;
    left: 3%;
    height: inherit !important;
  }


  .connect-section .connect-content-wrapper .dbl-cta-wrapper {
    padding: 4.5vw 0;
  }

  .connect-section .connect-content-wrapper .dbl-cta-wrapper > div {
    padding-right: 3vw;
  }

  .graphic-beams-2-wrapper {
    top: -66.8vw;
  }

  .graphic-beams-2-wrapper img {
    right: 0;
    width: 92vw;
  }

  /* Articles SECTION - Tablets */

  .articles-section {
    background: #f2f2f2;
    padding-top: 8.5vw;
    padding-bottom: 8vw;
  }

  .articles-section .articles-content-wrapper{
    flex-direction: column;
   /*  align-items: center;
    justify-content :flex-start; */
  }

  .articles-section .articles-content-wrapper .article{
    width: 70vw;
    height: 12vw;
    border-radius: 1vw;
    padding: .8vw 2vw;
    margin-bottom: 4vw;
  }

  .articles-section .articles-content-wrapper .article:last-of-type{
    margin-bottom: 0;
  }

  .articles-section .articles-content-wrapper .article-type{
    margin-bottom: .65vw;
  }
  
  .articles-section .articles-content-wrapper .icon{
    width: 1.5vw;
    margin-right: 1vw;
  }
  
  .articles-section .articles-content-wrapper .type{
    font-size: 1.2vw;
  }
  .articles-section .articles-content-wrapper .text{
    font-size: 3vw;
  }

  /* SHARE  - Tablet*/

  .share-wrapper{
    font-size: 25px;
    display: flex;

  }
  .share-wrapper ul{
  }

  .share-wrapper ul li{
    padding-left: 1em;
  }

  .share-wrapper ul li:first-child{
    padding-right: 1.2em;
    padding-left: 0;
  }
  .share-wrapper ul li:nth-child(2){
    padding-left: 1.2em;
  }

  .share-wrapper span{
    font-size: .95em;
  }
  
  

  /* CONTEST RULES SECTION */

  .contestrules-section {
    padding-top: 1vw;
  }

  .contestrules-section .container {
    padding-top: 3vw;
    padding-bottom: 5vw;
  }

  .contestrules-section ol {
    margin: 1vw 1vw 1vw 3.2vw;
    padding: 0;
  }

  .contestrules-section ul {
    margin: 1vw 1vw 1vw 2.4vw;
    padding: 0;
  }

  .contestrules-section .h5 {
    padding: 2vw 0 2.8vw;
    font-size: 3vw;
  }

  .contestrules-section p {
    padding: 0;
  }

  .contestrules-section .main-bodycopy {
    font-size: 2.6vw;
  }

  .contestrules-section ul li::marker {
    font-size: 1.8vw;
  }

  /* FOOTER SECTION */

  .footer-section {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }

  .footer-section .container {
    position: relative;
    width: 100vw;
    height: 22vw;
  }
  
  .footer-section .footer-list {
    padding-top: 5vw;
    width: 100%;
    height: 40vw;
    display: unset;
  }
  
  .footer-section .footer-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    justify-content: space-evenly;
  }

  .footer-section .footer-icons a {
    flex: unset;
  }

  .footer-section .footer-icons img {
    height: 3.5vw;
  }

  .footer-section .nav-wrapper a,
  .footer-section .nav-wrapper p {
    font-size: 2.2vw;
    color: #000000;
  }

  .footer-section .nav-wrapper p {
    line-height: 150%;
    text-align: center;
    padding: 5vw 0;
  }

  .footer-section .nav-wrapper li {
    padding: 0 2vw;
    margin: 0;
  }

  .nav-wrapper ul {
    justify-content: center;
  }

  .footer-section .nav-wrapper li:nth-child(1) {
    padding-left: 2vw;
  }

  /* HEADER ICON */

  .header-icon-wrapper img {
    left: 5.8vw;
    width: 10vw;
  }

  /* HEADLINES */

  .h1 {
    font-size: 4.2vw;
    padding: 1.5vw 0;
    margin: 0;
  }

  .h2 {
    font-size: 3.8vw;
    padding: 2vw 0;
    margin: 0;
  }

  .h4 {
    font-size: 3.6vw;
    padding: 1.5vw 0;
    margin: 0;
  }

  .h5 {
    font-size: 3vw;
    font-weight: 600;
    padding: 1.5vw 0;
    margin: 0;
  }

  .subhead {
    font-size: 4.1vw;
    font-weight: 600;
    padding: 1.5vw 0;
  }

  .r-margin {
    margin: 0 80% 0 0;
  }

  .l-margin {
    margin: 0 0 0 80%;
  }

  /* BODYCOPY */

  .main-bodycopy {
    font-size: 2.8vw;
    padding: 1.5vw 0;
  }

  /* CTA STYLES */

  .cta-wrapper a,
  .cta-wrapper div {
    font-size: 1.6vw;
    margin: auto;
    padding: 1.5vw 0;
    text-align: center;
    width: 24vw;
  }

  /* SEMI CIRCLE BG */

  .semi-circle-l {
    border-radius: 45vw 0 0 45vw;
  }

  .semi-circle-r {
    border-radius: 0 45vw 45vw 0;
  }

  /* OVERLAY SECTION */

  .download-overlay-wrapper .outer {
    width: 65vw;
    height: 37vw;
  }

  .download-overlay-wrapper .container {
    padding: 2vw 6vw;
    width: 100%;
  }

  .download-overlay-wrapper .checkboxes > div {
    padding: 1vw 3vw;
  }

  .download-overlay-wrapper .checkboxes div > label {
    width: 88%;
  }

  .download-overlay-wrapper label {
    font-size: 2vw;
    vertical-align: top;
  }

  .download-overlay-wrapper .dbl-cta-wrapper {
    justify-content: space-around;
    padding: 2vw 0 0 0;
  }

  .download-overlay-wrapper .download-button a {
    padding: 0.46vw 0;
  }

  .download-overlay-wrapper .back-button div {
    padding: 1.5vw 0;
  }

  .download-overlay-wrapper .close-button {
    width: 2.5vw;
    height: 2.5vw;
    position: relative;
    margin: 1.8vw 1.5vw 0vw auto;
  }

  .download-overlay-wrapper .close-button span {
    height: 0.3vw;
  }

  .download-overlay-wrapper .radio {
    width: 1.6vw;
    height: 1.6vw;
    margin: 0.8vw 1vw 0 0;
  }
  
  .download-overlay-wrapper .radio .inner-radio-active {
    width: 1.2vw;
    height: 1.2vw;
  }

  /* STICKY BANNER SECTION */
  .sticky-banner-section .sticky-banner-wrapper {
    display: flex;
    padding: 1vw 5.6vw 1vw 4vw;
    justify-content: flex-end;
  }

  .sticky-banner-section .sticky-banner-wrapper > div {
    flex: unset !important;
  }

  .sticky-banner-section .sticky-banner-wrapper .cta-wrapper {
    margin: unset;
  }

  .sticky-banner-section .sticky-banner-wrapper .cta-wrapper .contest-rules-cta {
    width: 18vw;
  }

  .sticky-banner-section .sticky-banner-wrapper .main-bodycopy p {
    font-size: 2.2vw;
  }
}


/*
===============================================================================
 MOBILE
===============================================================================
*/

@media screen and (max-width: 600px) {
  .container {
    padding: 0 5vw;
  }

  .hide-mb {
    display: none;
  }

  .content-wrapper {
    top: 40px;
  }

  .content-wrapper li {
    margin: 15px 0;
  }

  /* HEADER SECTION */

  .header-section .logo-wrapper {
    padding: 3vw 0;
  }

  .header-section .container {
    padding: 0 5vw;
  }
  
  .header-section .nav-wrapper-mb li a {
    font-size: 17px;
    padding: 3vw 5vw;
  }


  /* ABOUT SECTION */

  .about-section {
    height: 344vw;
  }

  .about-section .container {
    top: -42vw;
  }

  .about-section .container .div1 {
    padding: 4vw 5vw 15vw;
  }

  .about-section .div1 h2 {
    margin-right: 20vw;
  }

  .about-section .container .div2 {
    padding: 0;
}

  .green-oval {
    background: linear-gradient(#c2d500, #c8d602, #dcd700, #ecd900);
    border-radius: 10vw;
    padding: 9vw 5vw 12vw;
  }

  .green-oval .subhead {
      margin: 0 2vw;
  }

  /* DATA POINTS SECTION */

  .data-point-section .header-wrapper .main-bodycopy, .data-point-section .last-p {
    margin: 0px 5vw;
  }

  .data-point-section .data-point-content-wrapper {
    display: block;
    padding: 2vw 0;
  }

  .data-point-section .label-wrapper p {
    margin: 0px 5vw;
  }

  .data-point-section .donut-wrapper .percentage {
    font-size: 22vw;
    margin: 3.5vw 0 0 4vw;
  }

  .data-point-section .donut-wrapper .desc {
    font-size: 3.2vw;
  }

  /* BENEFITS */

  .benefits-section {
    padding-top: 16vw;
    padding-bottom: 10vw;
  }

  .benefits-section .header-wrapper {
    padding-top: 25vw;
  }
  
  .benefits-section .header-wrapper .h2 {
    margin: 0;
    padding: 0;
  }

  .benefits-section .benefit-content-wrapper .list-wrapper ul {
    padding: 0 5vw 0 5.5vw !important;
  }

  .benefit-content-wrapper .ico-info-wrapper img {
    width: 18vw;
  }

  .benefits-section .last-p {
    font-size: 4vw;
    line-height: 150%;
    padding: 1vw 10vw 1vw 0;
  }

  /* QUALIFIERS */

  .qualifiers-section {
    padding-top: 16vw;
    padding-bottom: 10vw;
  }

  .qualifiers-section .header-wrapper {
    padding-top: 25vw;
  }

  .qualifiers-section .header-wrapper .h2 {
    margin: 0;
    padding: 0;
  }

  .qualifiers-section .last-p {
    font-size: 4vw;
    line-height: 150%;
    padding: 1vw 10vw 1vw 0;
  }

  .qualifiers-section .in-addition {
    padding: 0 5vw 0vw;
  }

  /* PROCESS */

  .process-section {
    padding-top: 16vw;
    padding-bottom: 10vw;
  }

  .process-section .header-wrapper {
    padding-top: 25vw;
  }

  .process-section .header-wrapper .h2 {
    margin: 0;
    padding: 0;
  }

  .process-section .process-content-wrapper {
    height: 225vw;
    padding: 0;
  }

  .process-section h4 {
    font-size: 7vw;
  }

  .dot {
    height: 2.5vw;
    width: 2.5vw;
  }

  .process-section .dot {
    position: absolute;
    left: 5vw;
  }

  .process-1 .dot {
    top: 14.5vw;
  }

  .process-2 .dot {
    top: 18.5vw;
  }

  .process-3 .dot {
    top: 22.5vw;
  }

  .process-4 .dot {
    top: 24.5vw;
  }

  .process-section .line {
    top: 99vw;
    left: -77.5vw;
    width: 168vw;
  }

  .process-section .process-content-wrapper > div {
    height: 58vw;
    padding: 14vw 10vw 14vw 12vw;
    position: relative;
    left: 0 !important;
    width: 100%;
    border-radius: 1vw 1vw 4vw 4vw;
  }

  .process-section .process-content-wrapper > div:nth-child(1) {
    height: 54vw;
    padding: 10vw 10vw 10vw 12vw;
  }

  .process-section .process-content-wrapper > div:nth-child(2) {
    top: -4vw;
  }

  .process-section .process-content-wrapper > div:nth-child(3) {
    padding: 18vw 10vw 18vw 12vw;
    top: -8vw;
  }

  .process-section .process-content-wrapper > div:nth-child(4) {
    padding: 20vw 10vw 20vw 12vw;
    top: -12vw;
  }

  /* JUDGES */

  .judges-section {
    padding-top: 16vw;
    padding-bottom: 24vw;
  }

  .judges-section .header-wrapper {
    padding-top: 25vw;
  }

  .judges-section .header-wrapper .h2 {
    margin: 0;
    padding: 0;
  }

  .judges-section .ico-info-wrapper.t-center {
    width: 70vw;
    height: 75vw;
  }

  .judges-section .header-wrapper {
    padding-bottom: 8vw;
  }

  .judges-section .judges-content-wrapper {
    padding: 2vw 0 7.5vw;
  }

  .judges-section .judges-content-wrapper img {
    margin-bottom: 3vw;
    width: 50vw;
    height: 50vw;
  }

  

  .judges-section .clicker img {
    height: 7vw;
    position: absolute;
    top: -56vw;
  }

  .judges-section .clicker .right-arrow {
    right: 0;
  }

  .judges-section .clicker .left-arrow {
    left: 0;
  }

  /* HOW TO APPLY */

  .howtoapply-section {
    height: 129vw;
    padding: 1vw 0 3vw;
  }

  .howtoapply-section .container {
    padding: 0;
  }

  .howtoapply-section .yellow-oval {
    background: linear-gradient(#ffda00, #fedb00, #ffd400, #ffb901);
    border-radius: 10vw;
    padding: 6vw 0 12vw;
    top: -14.5vw;
    right: 0;
    left: 0;
  }

  .howtoapply-section .header-wrapper .h2 {
    margin: 0 10vw;
  }

  .howtoapply-section .apply-list {
    margin: 0 18vw;
  }

  /* CONNECT */

  .connect-section {
    padding-top: 16vw;
    padding-bottom: 14.7vw;
  }

  .connect-section .header-wrapper {
    padding-top: 25vw;
  }

  .connect-section .header-wrapper .h2 {
    margin: 0;
    padding: 0;
  }

  .connect-section .left-section {
    padding: 0;
  }

  .connect-section .right-section {
    padding: 2vw 0 0 0;
  }

  .connect-section .connect-content-wrapper .dbl-cta-wrapper {
    display: block;
  }

  .connect-section .connect-content-wrapper .dbl-cta-wrapper > div {
    padding-right: 0;
    padding-bottom: 4vw;
  }

  .connect-content-wrapper .right-section img,
  .connect-content-wrapper .right-section .player {
    width: 100%;
  }


  /* Articles SECTION - Mobile */

  .articles-section {
    padding-top: 8.5vw;
    padding-bottom: 8vw;
  }

  .articles-section .articles-content-wrapper .article{
    width: 90vw;
    height: inherit;
    border-radius: 4vw;
    padding: 10vw 5vw;
    margin-bottom: 5vw;
  }

  .articles-section .articles-content-wrapper .article:last-of-type{
    margin-bottom: 0;
  }

  .articles-section .articles-content-wrapper .article-type{
    justify-content: center;
    margin-bottom: 2.65vw;
  }
  
  .articles-section .articles-content-wrapper .icon{
    width: 5.5vw;
    margin-right: 2vw;
  }
  
  .articles-section .articles-content-wrapper .type{
    font-size: 4.2vw;
  }
  .articles-section .articles-content-wrapper .text{
    font-size: 8.5vw;
    text-align: center;
    display: block;
  }

  /* SHARE WRAPPER  Mobile*/

  .share-wrapper{
    display: flex;
    justify-content: center;
  }

  .share-wrapper ul li:nth-child(2){
    padding-left: 1.2em;
  }


    /* CONTEST RULES SECTION */

    .contestrules-section {
      padding-top: 1vw;
    }
  
    .contestrules-section .container {
      padding-top: 6vw;
      padding-bottom: 7vw;
    }
  
    .contestrules-section ol {
      margin: 1vw 1vw 1vw 4.8vw;
      padding: 0;
    }
  
    .contestrules-section ul {
      margin: 1vw 1vw 1vw 3.8vw;
      padding: 0;
    }
  
    .contestrules-section .h5 {
      padding: 2vw 0 4vw;
      font-size: 4.2vw;
    }
  
    .contestrules-section p {
      padding: 0;
    }
  
    .contestrules-section .main-bodycopy {
      font-size: 4vw;
    }
  
    .contestrules-section ul li::marker {
      font-size: 3vw;
    }

  /* FOOTER */

  .footer-section {
    padding-top: 10vw;
    padding-bottom: 55vw;
  }

  .footer-section .footer-icons img {
    height: 6vw;
  }

  .footer-section .nav-wrapper p {
    padding: 8vw 0;
  }

  .footer-section .nav-wrapper a, .footer-section .nav-wrapper p {
    font-size: 3.5vw;
  }

  .footer-section .nav-wrapper li {
    text-align: center;
  }

  /* ACCORDION */

  .accordion-wrapper {
    padding: 10vw 1px 4vw 1px;
  }
    
  .accordion-wrapper .acc-title, .accordion-wrapper .acc-description {
    padding: 0 5vw;
  }

  .accordion-wrapper .acc-title .h5 {
    text-transform: uppercase;
    padding: 4vw 15vw 4vw 0;
  }

  .accordion-wrapper .acc-description ul {
    padding: 0 0 0 5.5vw;
  }

  .accordion-wrapper .acc-description li {
    padding: 10px 0 20px 15px;
  }

  .accordion-wrapper .accordion .acc-description {
    padding-top: 1.5vw;
    padding-bottom: 4vw;
    transition: 0.3s ease-in-out;
  }

  /* DONUT */

  .donut-chart {
    width: 50vw;
    height: 50vw;
  }

  .inner-circle {
    width: 45vw;
    height: 45vw;
  }

  /* HEADER ICON */

  .header-icon-wrapper img {
    left: 5vw;
    width: 15vw;
  }

  /* HEADLINES */

  .h1 {
    font-size: 7.8w;
    padding: 1.5vw 0;
    margin: 0;
  }

  .h2 {
    font-size: 8vw;
    padding: 3vw 0;
    margin: 0;
  }

  .h4 {
    font-size: 7vw;
    padding: 1.5vw 0;
    margin: 0;
  }

  .h5 {
    font-size: 6vw;
    font-weight: 600;
    padding: 1.5vw 0;
    margin: 0;
  }

  .subhead {
    font-size: 8vw;
    font-weight: 600;
    padding: 1.5vw 0;
  }

  .r-margin {
    margin: 0 80% 0 0;
  }

  .l-margin {
    margin: 0 0 0 80%;
  }

  /* BODYCOPY */

  .main-bodycopy {
    font-size: 5.5vw;
    padding: 2vw 0;
  }

  /* CTA STYLES */

  .cta-wrapper a,
  .cta-wrapper div {
    font-size: 3.5vw;
    margin: auto;
    padding: 3vw 0;
    text-align: center;
    width: 90vw;
  }

  /* SEMI CIRCLE BG */

  .semi-circle-l {
    border-radius: 0
  }

  .semi-circle-r {
    border-radius: 0;
  }

  /* STICKY BANNER SECTION */

  .sticky-banner-section {
    /* z-index: 99; */
  }

  .sticky-banner-section .sticky-banner-wrapper {
    display: unset;
    padding: 1vw;
  }

  .sticky-banner-section .cta-wrapper {
    text-align: center;
  }

  .sticky-banner-section .sticky-banner-wrapper > div {
    flex: unset !important;
  }

  .sticky-banner-section .sticky-banner-wrapper .cta-wrapper .contest-rules-cta {
    width: 90vw;
  }

  .sticky-banner-section .sticky-banner-wrapper .cta-wrapper {
    margin: unset;
  }

  .sticky-banner-section .sticky-banner-wrapper .main-bodycopy {
    display: none;
  }

  /* OVERLAY SECTION */

  .download-overlay-wrapper .outer {
    transform: translate(0, 0);
    position: absolute;
    top: 0;
    left: 0;
    background: #F2F2F2;
    width: 100vw;
    height: 100vh;
    z-index: 160;
  }

  .download-overlay-wrapper .container {
    padding: 8vw 5vw;
    width: 100%;
  }

  .download-overlay-wrapper .checkboxes > div {
    padding: 2.5vw 1vw;
  }

  .download-overlay-wrapper .checkboxes div > label {
    width: 88%;
  }

  .download-overlay-wrapper label {
    font-size: 5.5vw;
  }

  .download-overlay-wrapper label .blue-highlight {
    color: #008FBE;
    text-decoration: underline;
    font-weight: 600;
  }

  .download-overlay-wrapper .dbl-cta-wrapper {
    display: block;
    padding: 15vw 0 0 0;
  }

  .download-overlay-wrapper .dbl-cta-wrapper > div {
    margin-bottom: 2.5vw;
  }

  .download-overlay-wrapper .download-button a,
  .download-overlay-wrapper .back-button div {
    padding: 3vw 0;
  }

  .download-overlay-wrapper .close-button {
    width: 28px;
    height: 24px;
    position: relative;
    margin: 8vw 5vw 4vw auto;
  }

  .download-overlay-wrapper .close-button span {
    height: 4px;
  }

  .download-overlay-wrapper .close-button:hover span {
    background-color: #dc3545;
  }

  .download-overlay-wrapper .radio {
    width: 4.5vw;
    height: 4.5vw;
    margin: 2vw 3.5vw 0 0;
  }
  
  .download-overlay-wrapper .radio .inner-radio-active {
    width: 3.2vw;
    height: 3.2vw;
  }
}
